// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@lingui/core";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.re.mjs";

var empty = Json_Encode$JsonCombinators.object([]);

var Messages = {
  empty: empty
};

var i18n = Core.i18n;

var detectedI18n = Core.setupI18n();

var I18nProvider = {};

function loadMessages(src) {
  return function (lang) {
    var tmp = lang === "ja" ? src.ja : src.en;
    return [tmp.then(function (messages) {
                  React.startTransition(function () {
                        i18n.load(lang, messages.messages);
                      });
                })];
  };
}

function loadMessagesForDetected(src) {
  return function (lang) {
    var tmp = lang === "ja" ? src.ja : src.en;
    return [tmp.then(function (messages) {
                  console.log("Loading");
                  console.log(lang);
                  console.log(messages.messages);
                  React.startTransition(function () {
                        detectedI18n.load(lang, messages.messages);
                      });
                })];
  };
}

var Util = {};

var UtilString = {};

export {
  Messages ,
  i18n ,
  detectedI18n ,
  I18nProvider ,
  loadMessages ,
  loadMessagesForDetected ,
  Util ,
  UtilString ,
}
/* empty Not a pure module */
